$highlight-color: $primary-color;

.form-floating-label {
	position: relative;

	[type='text'],
	[type='password'],
	[type='date'],
	[type='datetime'],
	[type='datetime-local'],
	[type='month'],
	[type='week'],
	[type='email'],
	[type='number'],
	[type='search'],
	[type='tel'],
	[type='time'],
	[type='url'],
	[type='color'],
	textarea {
		margin-bottom: 0;
		color: $dark-gray;
		font-size: .875rem;
		padding: 30px 1rem 1rem .75rem;
  }

  label {
	  color: $medium-gray;
	font-size: .875rem;
	  position: absolute;
	  pointer-events: none;
	  left: .75rem;
	  font-weight: normal;
	  top: .75rem;
	  transition: all 0.2s ease-in;
  }

  textarea ~ label {
	  top: 20px;
	  font-size: 18px;
	  font-weight: 300;
			  }

			  /* active state */
			  input:focus ~ label,
			  input:disabled[value] ~ label,
			  &.has-value input ~ label {
				  top: .1875rem;
				  font-size: .75rem;
				  color: $highlight-color;
										  }

										  textarea:focus ~ label,
										  &.has-value textarea ~ label {
											  top: 0;
											  font-size: 14px;
											  color: $highlight-color;
											  font-weight: 400;
																	}
																}








